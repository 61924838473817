/** 商标列表 */
<template>
  <div class="trademark-content">
    <Filters v-if="Object.keys(filters).length" :filters="filters" @selectFilter="selectFilter" />
    <AssetsList v-if="tradeData" assetsType="trade" :listData="tradeData" :page="filtersData.page" :total="pageTotal"
      :pageSize="10" :isMaxTotal="true" @setAssetsSort="setAssetsSort" @changePage="changePage">
      <template v-slot:default='{item}'>
        <router-link class="flex flex-1" target="_blank" :to="{path:'/trademark-details',query:{tid:item.tid}}">
          <el-image class="assets-img border-r6 mr-22" :src="item.tmsg" fit="contain"> </el-image>
          <div class="flex-column justify-around assets-info flex-1">
            <h4 class="assets-title">
              {{item.mno}}
              <el-tag v-if="item.cs" class="ml-10"> {{item.cs}} </el-tag>
            </h4>
            <el-row :gutter="10">
              <el-col :span="8">申请号/注册号：{{item.sn}}</el-col>
              <el-col :span="8">申请日：{{item.fd}}</el-col>
              <el-col :span="8">国际分类：{{item.nc_cn}}</el-col>
              <el-col :span="8">申请人：{{item.hno}}</el-col>
              <el-col :span="8">代理机构：{{item.aro}}</el-col>
              <!-- <el-col :span="8">初审公告期号：--</el-col> -->
            </el-row>
            <!-- <p class="assets-text">商标群组：3301、3304、3305</p> -->
          </div>
        </router-link>
      </template>
    </AssetsList>
  </div>
</template>
<script>
import Filters from '@/components/Filters.vue'
import AssetsList from '@/components/Assets/list.vue'
import { getTradeFilters, getTradeList } from '@/api/trademark'
export default {
  name: 'Trademark',
  components: {
    Filters,
    AssetsList
  },
  data () {
    return {
      filters: {},//筛选数据合集
      tradeData: null,
      pageTotal: 0,
      filtersDataDef: {
        keywords: this.$route.query.search_val,
        page: 1,
        sort: '-FD'
      },
      filtersData: {},// 获取数据--条件
    }
  },
  mounted () {
    this.filtersData = this.filtersDataDef
    this.getTradeFilters()
    this.getTradeList()
  },
  methods: {
    // 获取商标筛选条件
    async getTradeFilters () {
      try {
        let { data } = await getTradeFilters(this.$route.query.search_val)
        if (data && Object.keys(data).length) {
          let isVal = true
          for (const key in data) {
            isVal = data[key].map(i => {
              if (i == '') {
                return false
              }
            })
          }
          if (isVal) {
            this.filters = data
          }
        }
      } catch (error) {
        return false
      }
    },
    // 获取商标列表
    async getTradeList () {
      try {
        let { data, status } = await getTradeList(this.filtersData)
        if (status == 200) {
          this.tradeData = data.data
          this.pageTotal = data.total *= 1
          // 给数组每一项添加新字段
          this.tradeData.forEach(item => {
            this.$set(item, 'monitor_load', false)
          })
        }
      } catch (error) {

      }
    },
    // 筛选
    selectFilter (selectFilter) {
      this.filtersData.page = 1
      this.filtersData = { ...this.filtersDataDef, ...selectFilter }
      this.getTradeList()
    },
    // 排序
    setAssetsSort (sort) {
      this.filtersData.sort = sort
      this.filtersDataDef.sort = sort
      this.getTradeList()
    },
    // 分页切换
    changePage (page) {
      this.filtersData.page = page
      this.getTradeList()
    }
  },
}
</script>